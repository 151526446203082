import { html } from './bleskHeaderCommon';

export const configureFullNav = () => {
  /** @type {HTMLElement | null} */
  const fullNav = document.querySelector('.js-blesk-full-nav');

  if (!fullNav) {
    return;
  }

  /** @type {NodeListOf<HTMLElement>} */
  const openFullNavButtons = document.querySelectorAll('[data-open-nav]');

  /** @type {NodeListOf<HTMLButtonElement>} */
  const closeFullNavButtons = document.querySelectorAll('[data-close-nav]');

  /** @type {HTMLInputElement} */
  const searchInput = fullNav.querySelector('.js-blesk-full-nav__input--search');

  /** @type {Boolean} */
  let isFullNavOpened = false;

  const openFullNav = async () => {
    fullNav.classList.add('blesk-full-nav--show');
    isFullNavOpened = true;

    // zastavi runtime na 0.2s - podle delky animace show-navigation v _blesk-full-nav.scss
    await new Promise((resolve) => setTimeout(resolve, 200));

    html.style.overflow = 'hidden';
  };

  const closeFullNav = async () => {
    fullNav.classList.add('blesk-full-nav--hide');
    html.style.overflow = 'visible';

    // zastavi runtime na 0.2s - podle delky animace hide-navigation v _blesk-full-nav.scss
    await new Promise((resolve) => setTimeout(resolve, 200));

    fullNav.classList.remove('blesk-full-nav--show');
    fullNav.classList.remove('blesk-full-nav--hide');

    isFullNavOpened = false;
  };

  /** @param {String} linkText */
  const cncGa4ToggleMenu = (linkText) => {
    if (Array.isArray(window.dataLayer)) {
      window.dataLayer.push({
        event: 'clickTo_navigation',
        menu: {
          type: 'menu',
        },
        link: {
          text: linkText,
        },
      });
    }
  };

  /** @param {Event} e */
  const openButtonOnClick = (e) => {
    e.preventDefault();

    openFullNav();

    if (e.currentTarget.classList.contains('js-focus-search-input')) {
      searchInput.focus();
      cncGa4ToggleMenu('openSearch');
    } else {
      cncGa4ToggleMenu('open');
    }
  };

  const configureFullNavOpening = () => {
    [...openFullNavButtons].forEach((openButton) => {
      openButton.addEventListener('click', openButtonOnClick);
    });
  };

  /** @param {Event} e */
  const closeButtonOnClick = (e) => {
    e.preventDefault();

    closeFullNav();

    cncGa4ToggleMenu('close');
  };

  /** @param {Event} e */
  const closeButtonOnKeydown = (e) => {
    /** @type {Boolean} */
    const isEscapeKey = e.keyCode === 27;

    if (isFullNavOpened && isEscapeKey) {
      e.preventDefault();

      closeFullNav();

      cncGa4ToggleMenu('closeEsc');
    }
  };

  const configureFullNavClosing = () => {
    [...closeFullNavButtons].forEach((closeButton) =>
      closeButton.addEventListener('click', closeButtonOnClick)
    );

    document.addEventListener('keydown', closeButtonOnKeydown);
  };

  configureFullNavOpening();
  configureFullNavClosing();
};
